<template>
  <div class="home">
    
    <motoBusqueda></motoBusqueda>
    <ultimosInmuebles></ultimosInmuebles>
      
  </div>
</template>

<style>
.rtrtt{
  color: #00174a;
}
</style>

<script>
import axios from 'axios'
import motoBusqueda from '../components/motorBusqueda'
import ultimosInmuebles from '../components/ultimosInmuebles'
//import router from '../../administrador/src/router'
export default {
  name: 'Home',
  data(){
    return {
      items:[
        {src: 'http://inmobiliaria.curubasoft.com/img/img2Inmobiliaria.jpg'},
        {src: 'http://inmobiliaria.curubasoft.com/img/img1Inmobiliaria.jpg'},
      ],
      inmuebles:[]
    }
  },
  methods:{
    /*irA(e){
      router.push({name: 'InmueblesUltimos', params:{id:e}})
    },*/
    async loadInmuebles(){
      axios.post('https://inmoglobalve.com.ve/fdjfdhdjhddfdfd.php').then(res=>{
        this.inmuebles = res.data
        console.log(this.inmuebles)
      })
    }
  },
  created(){
    this.loadInmuebles()
  },
  components:{
    motoBusqueda,ultimosInmuebles 
  }
}
</script>
