<template>
  <div style="margin:0 auto;display:block;width:80%;">

      <p style="padding:25px 0px 25px 0px;font-size:30px;color:#333;font-weight:600;text-align:center">
          Inmuebles Global
      </p>
      <div style="margin-bottom:60px;margin:20px auto;display:block;width:50px;border-top:solid 4px #D41700"></div>


        <v-layout row justify-center>
            <v-flex xs12 md4 v-for="(item, i) in ultimosInuebles" :key="i"
            style="margin-bottom:50px;"
            >
                
                <div style="margin:0 auto;display:block;width:90%">
                    <v-carousel hide-delimiters height="300"
                    next-icon="fas fa-caret-right"
                    prev-icon="fas fa-caret-left"
                    >
                        <v-carousel-item v-if="item.img1 != ''" :src="item.img1">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img2 != ''" :src="item.img2">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img3 != ''" :src="item.img3">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img4 != ''" :src="item.img4">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img5 != ''" :src="item.img5">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img6 != ''" :src="item.img6">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img7 != ''" :src="item.img7">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img8 != ''" :src="item.img8">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img9 != ''" :src="item.img9">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img10 != ''" :src="item.img10">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                    </v-carousel>

                    <v-card style="background:white;border-radius:0;" flat>
                        <v-card-actions>
                <v-btn text @click="irInmueble({asesor: item.pr_asesor_uid, id:item.pr_uid})"
                style="
                    border-radius:20px;color:grey;text-transform:none;
                    font-weight:700;text-decoration:none;font-size:14px;
                    ">Ver Detalles</v-btn>
                <v-spacer></v-spacer>
                <p class="pt-3" v-if="item.pr_precio != ''">
                    Ref: ${{formatNumber(item.pr_precio)}}
                </p>
                <p class="pt-3" v-if="item.pr_canon != ''">
                    Ref: ${{formatNumber(item.pr_canon)}}
                </p>
                        </v-card-actions>
                    </v-card>
                
                </div>
            </v-flex>
        </v-layout>


  </div>
</template>

<style>
.dfdfg{
    width:100%;
background: rgba(0,88,176,1);
background: -moz-linear-gradient(top, rgba(0,88,176,1) 0%, rgba(1,43,82,1) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,88,176,1)), color-stop(100%, rgba(1,43,82,1)));
background: -webkit-linear-gradient(top, rgba(0,88,176,1) 0%, rgba(1,43,82,1) 100%);
background: -o-linear-gradient(top, rgba(0,88,176,1) 0%, rgba(1,43,82,1) 100%);
background: -ms-linear-gradient(top, rgba(0,88,176,1) 0%, rgba(1,43,82,1) 100%);
background: linear-gradient(to bottom, rgba(0,88,176,1) 0%, rgba(1,43,82,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0058b0', endColorstr='#012b52', GradientType=0 );
}
</style>

<script>
import axios from 'axios'
export default {
    data(){
        return {
            ultimosInuebles:[]
        }
    },
    methods:{
        formatNumber(n) {
            n = String(n).replace(/\D/g, "");
        return n === '' ? n : Number(n).toLocaleString('es');
        },
        irInmueble(e){
            const _ID = e.id;
            const _ASESOR = e.asesor;
            const _URL = "/Inmueble?inmu="+_ID+"&asesor="+_ASESOR;
            window.open(_URL, "_self")
        },
        async loadUltimosProductos(){
            try {
                await axios.post('https://inmoglobalve.com.ve/php/Productos/ultimosProductos.php').then(res=>{
                    this.ultimosInuebles = res.data
                })
            } catch (error) {
                console.log(error)
            }
        }
    },
    created(){
        this.loadUltimosProductos()
    }
}
</script>

<style>

</style>